<template>
  <div :id="'bg_'+elementId" class="bg-chart" :style="{ width: (this.width), height: (this.height) }"
    style="margin-left:  auto; margin-right: auto;" >
    <canvas :id="elementId" :style="{ width: (this.width), height: (this.height) }"></canvas>
    <div class="chart-label percent" :style="{'top': '-'+(this.offset)+'px'}">
      {{ showNumber(percent) }}%
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Charts
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import 'chart.js/dist/Chart.min.css'

// Por padrão label vem habilitada para todos os gráficos (Removendo para poder colocar apenas nos que desejar)
Chart.plugins.unregister(ChartDataLabels)

export default {
  inheritAttrs: false,
  name: 'DonnutChartV2',
  components: {
  },
  data () {
    return {
      refName: this.refInput || 'field_' + Math.random().toString().substr(2),
      percent: 0.0,
      chartData: [],
      chartObject: null,
      chartOptions: {}
    }
  },
  updated () {
    this.chartObject = this.buildChart()
  },
  methods: {
    calcPercent (value, total) {
      let percent = 0
      if (total === 0) {
        if (value <= 0) {
          percent = 0
        } else {
          percent = 100
        }
      } else {
        percent = value * 100 / total
      }

      return percent
    },
    buildChart () {
      let goal = 0
      let series = []
      let data = []
      let colors = []

      // If goal less than 0 change to display a correct percent (100%)
      goal = this.goal
      if (this.goal < 0) {
        goal = 0
      }

      this.percent = this.calcPercent(this.value, goal)

      if (this.value > goal) {
        data.push(this.value - goal)
        colors.push('#008f59')

        data.push(goal)
        colors.push('#00b770')

        series.push({
          data: data,
          backgroundColor: colors
        })
      } else if (this.value <= 0 && goal <= 0) {
        data.push(100)
        colors.push(this.emptyColor)

        series.push({
          data: data,
          backgroundColor: colors
        })
      } else if (this.percent >= this.percentMark) {
        data.push(this.value)
        colors.push('#00b770')

        if (this.value < goal) {
          data.push(goal - this.value)
          colors.push(this.emptyColor)
        }

        series.push({
          data: data,
          backgroundColor: colors
        })
      } else if (this.percent < (this.percentMark / 2)) {
        data.push(this.value)
        colors.push('#ff0000')

        data.push(goal - this.value)
        colors.push(this.emptyColor)

        series.push({
          data: data,
          backgroundColor: colors
        })
      } else {
        data.push(this.value)
        // colors.push('#ffee00')
        colors.push('#eecc00')

        data.push(goal - this.value)
        colors.push(this.emptyColor)

        series.push({
          data: data,
          backgroundColor: colors
        })
      }

      this.chartData = series

      let me = this

      this.chartOptions = {
        type: 'doughnut',
        data: {
          datasets: this.chartData,
          labels: []
        },
        options: {
          plugins: {
            datalabels: {
              align: function (context) {
                var index = context.dataIndex
                var value = context.dataset.data[index]
                // var invert = Math.abs(value) <= 1
                return value < 1 ? 'end' : 'start'
              },
              anchor: 'end',
              backgroundColor: null,
              borderColor: null,
              borderRadius: 4,
              borderWidth: 1,
              // color: '#223388',
              font: {
                size: 11,
                weight: 600
              },
              offset: 4,
              padding: 0,
              formatter: function (value) {
                return me.showNumber(value) + '%'
              }
            }
          },
          elements: {
            center: {
              text: '0%',
              color: '#00bcd4', // Default is #000000
              fontStyle: 'Arial', // Default is Arial
              sidePadding: 60 // Defualt is 20 (as a percentage),
            }
          },
          tooltipFontSize: 10,
          tooltips: {
            enabled: false,
            callbacks: {
              title: function (tooltipItem, data) {
                let label
                if (data.datasets[tooltipItem[0]]) {
                  label = data.datasets[tooltipItem[0].datasetIndex].label[tooltipItem[0].index]
                }

                if (!label) {
                  return null
                }

                return data['labels'][tooltipItem[0]['index']]
              },
              label: function (tooltipItem, data) {
                let valor
                let label

                if (data.datasets[tooltipItem.datasetIndex]) {
                  valor = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]

                  if (data.datasets[tooltipItem.datasetIndex].label) {
                    label = data.datasets[tooltipItem.datasetIndex].label[tooltipItem.index]
                  }
                }

                if (valor <= 0) {
                  return null
                }

                if (label) {
                  return label + ' (' + me.showNumber(valor) + '%)'
                }

                return null
              }
            },
            backgroundColor: '#FFF',
            titleFontSize: 14,
            titleSpacing: 10,
            titleMarginBottom: 10,
            bodySpacing: 4,
            titleFontColor: '#3f3f3f',
            bodyFontColor: '#333',
            bodyFontSize: 13,
            cornerRadius: 3,
            yPadding: 10,
            xPadding: 10,
            displayColors: true
          },
          legend: {
            display: false
          },
          cutoutPercentage: (100 - this.donutWidth),
          // If need the circle do not complete on 100 percent
          // circumference: 1.6 * Math.PI,
          responsive: true,
          maintainAspectRatio: true,
          animation: {
            animateScale: true,
            animateRotate: true
          }
        }
      }
      let processesDonnutctx = document.getElementById(this.elementId).getContext('2d')

      return new Chart(processesDonnutctx, this.chartOptions)
    },
    // Filters
    numberFormat (number, decimals, decPoint, thousandsSep) {
      return util.numberFormat(number, decimals, decPoint, thousandsSep)
    },
    showNumber (number, thousandsSep, defaultValue) {
      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      let decimals = 0
      let decPoint = ','
      thousandsSep = thousandsSep || '.'
      // eslint-disable-next-line
      if (number != parseInt(number)) {
        decimals = 2
      }

      if (number) {
        return this.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    },
    hasSlot (name = 'default') {
      return !!this.$slots[ name ] || !!this.$scopedSlots[ name ]
    }
  },
  computed: {
    offset () {
      if (this.topOffset !== undefined) {
        return parseInt(this.height) - this.topOffset
      }

      return this.height / 2
    }
  },
  props: {
    elementId: {
      type: String,
      required: true
    },
    emptyColor: {
      type: String,
      required: false,
      default: '#F3F1ED'
    },
    indicator: String,
    // value
    value: {
      type: Number,
      default: 0
    },
    goal: {
      type: Number
    },
    topOffset: {
      type: Number,
      required: false
    },
    width: {
      type: String,
      required: true
    },
    height: {
      type: String,
      required: true
    },
    donutWidth: {
      type: Number,
      required: false,
      default: 22
    },
    percentMark: {
      type: Number,
      required: false,
      default: 100
    }
  }
}
</script>

<style scoped>
.chart-label {
  text-align: center;
  position: relative;
  z-index: 0;
  width: 65%;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  color: #84754E;
  font-size: x-small;
}

.bg-chart {
  margin-bottom: 10px;
}
</style>
